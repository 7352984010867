import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import UploadFileModal from '../../upload-file-modal/uploadFileModal.component';
import { getCategories, getSubcategories, updateContent } from '../../../services/ContentService';
import { searchUsers } from '../../../services/UserService';
import DropdownSelect from '../../dropdown-select/dropdownSelect.component';
import DateTimeInput from '../../date-time-input/dateTimeInput.component';
import CustomInput from '../../custom-input/customInput.component';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';
import SelectButton from "../../notification-create/select-button/selectButton.component";
import ModalInviteGroup from "../../groups-view/create-group/modal-invite-group/modalInviteGroup.component";
import HomeUserTreeModal from "../../content-view/home-user-tree-modal/homeUserTreeModal.component";
import styles from './styles/updateContent.module.scss';
import greyImage from '../../../assets/img/imagegrey.png';
import blueImage from '../../../assets/img/imageblue.png';
import { getImage } from '../../../services/BlobService';
import { Icon } from '@iconify/react';
import CustomModal from '../../custom-modal/customModal.component';

const ID_ACTIVIDADES_GRUPO = 4;
const ID_CANALES_TV = 1;
const ID_LIVES_YOUTUBE = 6;

class UpdateContent extends Component {
  state = {
    contentData: {
      id: '',
      resource: '',
      name: '',
      description: '',
      startDate: '',
      endDate: ''
    },
    isResourceAutoGenerated: false,
    categories: [],
    subcategories: [],
    creationUsers: [],
    selectedCategory: null,
    selectedSubcategory: null,
    selectedCreationUser: null,
    selectedStatus: { key: true, value: this.props.t("CONTENT.CREATE.Activated") },
    imageSrc: greyImage,
    isUploadModalOpen: false,
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
    selectedFile: null,
    errors: {},
    validate: false,
    isLoading: false,
    isUpdating: false,
    showUpdateConfirmationModal: false,
    guestEmails: [],
    isInviteModalOpen: false,
    isHomeUserTreeModalOpen: false,
    selectedUsers: new Set(),
    showDateRow: false,
  };

  async componentDidMount() {
    try {
      this.setState({ isUpdating: true });
      const categories = await getCategories();
      this.setState({
        categories: categories.map(cat => ({
          key: cat.id,
          value: this.props.t('CATEGORY.' + cat.name)
        }))
      });

      const subcategories = await getSubcategories();
      this.setState({ subcategories: subcategories.map(sub => ({ key: sub.id, value: sub.name })) });

      const content = this.props.content;

      const selectedHomes = new Set(content.homeIds || []);
      const selectedUsers = new Set(content.userIds || []);

      const selectedCategory = content.category ? {
        key: content.category.id,
        value: this.props.t('CATEGORY.' + content.category.name)
      } : null;

      const selectedSubcategory = content.subcategory ? {
        key: content.subcategory.id,
        value: content.subcategory.name
      } : null;

      const selectedCreationUser = content.creator ? {
        key: content.creator.id,
        value: content.creator.email
      } : null;

      const isResourceAuto = selectedCategory && selectedCategory.key === ID_ACTIVIDADES_GRUPO;
      const isAllowedCategory = selectedCategory && (
        selectedCategory.key === ID_ACTIVIDADES_GRUPO || selectedCategory.key === ID_LIVES_YOUTUBE
      );

      this.setState({
        contentData: {
          id: content.id,
          resource: isResourceAuto ? this.props.t("CONTENT.LABEL.Autogenerated") : content.resource,
          name: content.name,
          description: content.description,
          startDate: content.startDate,
          endDate: content.endDate
        },
        selectedCategory,
        selectedSubcategory,
        selectedCreationUser,
        selectedStatus: content.active ? { key: true, value: this.props.t("CONTENT.CREATE.Activated") } : { key: false, value: this.props.t("CONTENT.CREATE.Deactivated") },
        selectedFile: content.image || null,
        selectedHomes,
        selectedUsers,
        isResourceAutoGenerated: isResourceAuto,
        guestEmails: content.guestEmails || [],
        showDateRow: isAllowedCategory,
      }, () => {
        if (this.state.selectedFile) {
          this.fetchImage(this.state.selectedFile);
        } else {
          this.setState({ isLoading: false });
        }
      });
      this.setState({ isUpdating: false });
    } catch (error) {
      console.error('Error obteniendo categories, subcategories, homes, o users:', error);
      this.setState({ isUpdating: false, isLoading: false });
    }
  }

  fetchImage = async (imageId) => {
    if (imageId) {
      this.setState({ isLoading: true });
      try {
        const url = await getImage(imageId);
        this.setState({ imageSrc: url, isLoading: false });
      } catch (error) {
        console.error('Error fetching image:', error);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleSave = () => {
    const hasErrors = this.validateFields();
    this.setState({ validate: true });

    if (hasErrors) return;
    this.setState({ showUpdateConfirmationModal: true });
  };

  handleConfirmUpdate = async () => {
    this.setState({ showUpdateConfirmationModal: false, isUpdating: true });
    try {
      const {
        selectedCategory,
        selectedSubcategory,
        selectedUsers,
        selectedCreationUser,
        selectedStatus,
        selectedFile,
        contentData,
        isResourceAutoGenerated,
        guestEmails,
      } = this.state;

      const isLive = selectedCategory && selectedCategory.key === ID_CANALES_TV && selectedStatus.key === true;

      const data = {
        id: this.props.content.id,
        file: selectedFile,
        resource: isResourceAutoGenerated ? null : contentData.resource,
        name: contentData.name,
        description: contentData.description,
        status: selectedStatus.key ? true : false,
        categoryId: selectedCategory?.key || null,
        subcategoryId: selectedSubcategory?.key || null,
        creationUserId: selectedCreationUser?.key || null,
        userIds: Array.from(selectedUsers),
        guestEmails,
        startDate: contentData.startDate,
        endDate: contentData.endDate,
        live: isLive,
      };
      await updateContent(data);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'ok',
        feedbackMessage: this.props.t("CONTENT.EDIT.SuccessMessage"),
      });
    } catch (error) {
      console.error('Error editando content:', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("CONTENT.EDIT.ErrorMessage"),
      });
    } finally {
      this.setState({ isUpdating: false });
    }
  };

  handleCancelUpdate = () => {
    this.setState({ showUpdateConfirmationModal: false });
  };

  validateFields = () => {
    const { contentData, isResourceAutoGenerated, selectedCategory, selectedCreationUser, selectedUsers, guestEmails } = this.state;
    const errors = {};

    if (!contentData.name.trim()) {
      errors.name = this.props.t("CONTENT.CREATE.ValidationRequired");
    }
    if (!selectedCreationUser) {
      errors.selectedCreationUser = this.props.t("CONTENT.CREATE.ValidationRequired");
    }
    if (!selectedCategory) {
      errors.selectedCategory = this.props.t("CONTENT.CREATE.ValidationRequired");
    }
    if (!selectedUsers || (selectedUsers.size === 0 && guestEmails.length === 0)) {
      errors.selectedUsers = this.props.t("CONTENT.CREATE.ValidationRequired");
    }
    if (!isResourceAutoGenerated && !contentData.resource.trim()) {
      errors.resource = this.props.t("CONTENT.CREATE.ValidationRequired");
    }

    this.setState({ errors, validate: true });

    return Object.keys(errors).length > 0;
  }

  setSelectedCategory = (selectedOptions) => {
    const category = Array.isArray(selectedOptions) ? selectedOptions[0] : null;
    const isResourceAuto = category && category.key === ID_ACTIVIDADES_GRUPO;
    const isAllowedCategory = category && (
      category.key === ID_ACTIVIDADES_GRUPO || category.key === ID_LIVES_YOUTUBE
    );

    this.setState((prevState) => ({
      selectedCategory: category,
      isResourceAutoGenerated: isResourceAuto,
      showDateRow: isAllowedCategory,
      contentData: {
        ...prevState.contentData,
        resource: isResourceAuto ? this.props.t("CONTENT.LABEL.Autogenerated") : ''
      }
    }));
  }

  setSelectedSubcategory = (selectedOptions) => {
    this.setState({ selectedSubcategory: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  setSelectedCreationUser = (selectedOptions) => {
    this.setState({ selectedCreationUser: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  setSelectedStatus = (selectedOptions) => {
    this.setState({ selectedStatus: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  searchUsers = async (term) => {
    try {
      let users = await searchUsers(term);
      users = users.filter(user => user.userType.name === "Admin" || user.userType.name === "Professional");
      return users.map(user => ({ key: user.id, value: user.email }));
    } catch (error) {
      console.error('Error buscando users:', error);
      return [];
    }
  }

  handleInputChange = (name, value) => {
    if (name === 'resource' && this.state.isResourceAutoGenerated) {
      return;
    }

    this.setState((prevState) => ({
      contentData: {
        ...prevState.contentData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: null
      }
    }));
  }

  handleDateChange = (dateType, dateValue) => {
    this.setState((prevState) => ({
      contentData: {
        ...prevState.contentData,
        [dateType]: dateValue
      }
    }));
  }

  handleImageMouseEnter = () => {
    if (this.state.selectedFile) return;
    this.setState({ imageSrc: blueImage });
  };

  handleImageMouseLeave = () => {
    if (this.state.selectedFile) return;
    this.setState({ imageSrc: greyImage });
  };

  handleImageClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  handleEditClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalOpen: false });
  };

  handleFileSelect = (file) => {
    this.setState({ selectedFile: file, imageSrc: URL.createObjectURL(file) });
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
    if (this.state.feedbackType === 'ok') {
      this.props.onClose(true);
    }
  };

  openInviteModal = () => {
    this.setState({ isInviteModalOpen: true });
  };

  closeInviteModal = () => {
    this.setState({ isInviteModalOpen: false });
  };

  handleInviteSubmit = (guestEmails) => {
    this.setState({ guestEmails, isInviteModalOpen: false });
  };

  openHomeUserTreeModal = () => {
    this.setState({ isHomeUserTreeModalOpen: true });
  };

  submitHomeUserTreeModal = (selectedUsers) => {
    this.setState({
      isHomeUserTreeModalOpen: false,
      selectedUsers: new Set(selectedUsers),
      errors: {
        ...this.state.errors,
        selectedUsers: null,
      }
    });
  };

  closeHomeUserTreeModal = () => {
    this.setState({ isHomeUserTreeModalOpen: false });
  };

  render() {
    const {
      showUpdateConfirmationModal,
      categories,
      subcategories,
      creationUsers,
      selectedCreationUser,
      selectedCategory,
      selectedSubcategory,
      selectedStatus,
      contentData,
      imageSrc,
      isUploadModalOpen,
      isFeedbackModalOpen,
      feedbackType,
      feedbackMessage,
      validate,
      isLoading,
      isUpdating,
      isHomeUserTreeModalOpen,
      isResourceAutoGenerated,
      errors,
      guestEmails,
      isInviteModalOpen,
      selectedUsers,
      showDateRow,
    } = this.state;
    const { t } = this.props;
    const buttons = [
      { label: t("CONTENT.CREATE.SaveButton"), className: styles.saveButton, onClick: this.handleSave },
      { label: t("CONTENT.CREATE.CancelButton"), className: styles.cancelButton, onClick: () => this.props.onClose(false) }
    ];

    const updateConfirmationButtons = [
      { label: t("CONTENT.CREATE.ConfirmButton"), className: styles.saveButton, onClick: this.handleConfirmUpdate },
      { label: t("CONTENT.CREATE.CancelButton"), className: styles.cancelButton, onClick: this.handleCancelUpdate }
    ];

    const statusOptions = [
      { key: true, value: t("CONTENT.CREATE.Activated") },
      { key: false, value: t("CONTENT.CREATE.Deactivated") }
    ];

    return (
      <>
        {(isUpdating || isLoading) && <LoadingScreen />}
        <Modal title={t("CONTENT.EDIT.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
          <div className={styles.formContent}>
            <div className={styles.row}>
              <div className={styles.imageFormHeader}>
                <label className={styles.label}>{t("CONTENT.CREATE.Image")}</label>
                <span className={styles.editLabel} onClick={this.handleEditClick}>{t("CONTENT.CREATE.editImage")}</span>
              </div>
              <div className={styles.imageContainer}>
                {isLoading ? (
                  <div className={styles.loadingIndicator}>
                    <Icon icon="eos-icons:loading" width="45" height="45" />
                  </div>
                ) : (
                  <img
                    src={imageSrc}
                    alt=""
                    className={styles.image}
                    onMouseEnter={this.handleImageMouseEnter}
                    onMouseLeave={this.handleImageMouseLeave}
                    onClick={this.handleImageClick}
                  />
                )}
              </div>
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Resource")}{!isResourceAutoGenerated && <span className={styles.requiredMark}> *</span>}</label>
              <CustomInput
                type="text"
                name="resource"
                value={isResourceAutoGenerated ? t("CONTENT.LABEL.Autogenerated") : contentData.resource}
                onChange={(value) => this.handleInputChange('resource', value)}
                placeholder={t("CONTENT.CREATE.ResourcePlaceholder")}
                customClass={`${styles.inputField} ${isResourceAutoGenerated ? styles.disabledInput : ''}`}
                validate={validate}
                validators={[{ regex: /.+/, message: t("CONTENT.CREATE.ValidationRequired") }]}
                errorMessage={errors.resource}
                disabled={isResourceAutoGenerated}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Name")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="name"
                value={contentData.name}
                onChange={(value) => this.handleInputChange('name', value)}
                placeholder={t("CONTENT.CREATE.NamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("CONTENT.CREATE.ValidationRequired") }]}
                validate={validate}
                errorMessage={errors.name}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Description")}</label>
              <CustomInput
                type="text"
                name="description"
                value={contentData.description}
                onChange={(value) => this.handleInputChange('description', value)}
                placeholder={t("CONTENT.CREATE.DescriptionPlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.CreationUser")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={creationUsers}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectCreationUserPlaceholder")}
                setSelectedOptions={this.setSelectedCreationUser}
                searchable={true}
                search={this.searchUsers}
                selectedOptions={selectedCreationUser ? [selectedCreationUser] : []}
                customClass={styles.selectCreationUser}
                required={true}
                validate={validate}
                errorMessage={errors.selectedCreationUser}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Category")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={categories}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectCategoryPlaceholder")}
                setSelectedOptions={this.setSelectedCategory}
                customClass={styles.selectCategory}
                selectedOptions={selectedCategory ? [selectedCategory] : []}
                required={true}
                validate={validate}
                errorMessage={errors.selectedCategory}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Subcategory")}</label>
              <DropdownSelect
                options={subcategories}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectSubcategoryPlaceholder")}
                setSelectedOptions={this.setSelectedSubcategory}
                selectedOptions={selectedSubcategory ? [selectedSubcategory] : []}
                customClass={styles.selectSubcategory}
              />
            </div>
            <div className={`${styles.row} ${styles.spaceBetween}`}>
              <div className={styles.statusGroup}>
                <label className={styles.label}>{t("CONTENT.CREATE.Status")}<span className={styles.requiredMark}> *</span></label>
                <DropdownSelect
                  options={statusOptions}
                  isMultiple={false}
                  placeholder={t("CONTENT.CREATE.SelectStatusPlaceholder")}
                  setSelectedOptions={this.setSelectedStatus}
                  selectedOptions={selectedStatus ? [selectedStatus] : []}
                  customClass={styles.selectStatus}
                  required={true}
                  validate={validate}
                />
              </div>
              <div className={styles.selectUsersGroup}>
                <label className={styles.label}>{t("CONTENT.CREATE.SelectHomesAndUsers")}<span className={styles.requiredMark}> *</span></label>
                <div className={styles.selectUsersButton}>
                  <SelectButton
                    icon="uit:house-user"
                    label={t("CONTENT.CREATE.SelectHomesAndUsersButton")}
                    selectedCount={selectedUsers.size}
                    onClick={this.openHomeUserTreeModal}
                    validate={validate}
                  />
                </div>
                {isResourceAutoGenerated && (
                  <div className={styles.buttonGroup}>
                    <SelectButton
                      icon="ph:envelope-thin"
                      label={t("GROUPS.LABEL.AddGuestEmailsButton")}
                      selectedCount={guestEmails.length}
                      onClick={this.openInviteModal}
                    />
                  </div>
                )}
              </div>
            </div>

            {showDateRow && (
              <div className={styles.row}>
                <label className={styles.label}>{t("CONTENT.CREATE.Date")}</label>
                <DateTimeInput
                  label={t("CONTENT.CREATE.StartDate")}
                  customClass={styles.dateClass}
                  value={contentData.startDate}
                  onChange={(date) => this.handleDateChange('startDate', date)}
                />
                <DateTimeInput
                  label={t("CONTENT.CREATE.EndDate")}
                  customClass={styles.dateClass}
                  value={contentData.endDate}
                  onChange={(date) => this.handleDateChange('endDate', date)}
                />
              </div>
            )}
          </div>
        </Modal>
        {showUpdateConfirmationModal && (
          <CustomModal
            title={t("CONTENT.EDIT.EditConfirmationTitle")}
            customText={t("CONTENT.EDIT.EditConfirmationMessage")}
            icon="mdi:event-edit"
            iconClassName={styles.updateIcon}
            buttons={updateConfirmationButtons}
            onClose={this.handleCancelUpdate}
          />
        )}
        {isUploadModalOpen && <UploadFileModal onClose={this.closeUploadModal} onFileSelect={this.handleFileSelect} />}
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
        {isInviteModalOpen && (
          <ModalInviteGroup
            guestEmails={guestEmails}
            onSubmit={this.handleInviteSubmit}
            onClose={this.closeInviteModal}
          />
        )}
        {isHomeUserTreeModalOpen && (
          <HomeUserTreeModal
            initialSelectedHomes={Array.from(this.state.selectedHomes)}
            initialSelectedUsers={Array.from(this.state.selectedUsers)}
            onSubmit={(selectedHomes, selectedUsers) => this.submitHomeUserTreeModal(selectedHomes, selectedUsers)}
            onClose={this.closeHomeUserTreeModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(UpdateContent);
