import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { callGroup, getGroupById } from '../../../services/GroupService';
import { changeUserStateToEndCall, changeUserStateToStartCall } from '../../../services/UserStateService';
import { State } from '../../../utils/StateEnum';
import { setVideocallInfo } from '../../../redux/slices/videocallSlice';
import { setUserStatus } from '../../../redux/slices/userSlice';
import { updateGroup } from '../../../redux/slices/groupSlice';
import ConfirmModal from '../confirm-modal/confirmModal.component';
import styles from  './styles/buttonJoinCallNotification.module.scss';
import { hasFamiliarRole } from '../../../utils/credentials.util';
import { setSelectedComponent } from '../../../redux/slices/layoutSlice';
import { useNavigate } from 'react-router-dom';

class BtnJoinCallNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: null,
            showCallModal: false,
            showInLiveCallModal: false,
        };
    }

    async componentDidMount() {
        const group = await getGroupById(this.props.groupId);
        this.setState({ group });
    }

    handleConfirmCall = async () => {
        const { group } = this.state;
        try {
            callGroup(group.id)
                .then(callResponse => {
                    console.log("[BUTTONJOINCALL] Call initiated:", callResponse);
                    this.props.setVideocallInfo(callResponse);
                    this.props.setUserStatus({ status: State.BUSY });
                    this.props.updateGroup({ group: { ...group, live: true } });
                    debugger;
                    changeUserStateToStartCall(this.props.userId);
                    console.debug(callResponse);
                    if (hasFamiliarRole()) {
                        this.props.setSelectedComponent('outlet');
                    }
                    this.props.onClose();
                    window.location.href = `/videocall`;
                })
                .catch(error => {
                    console.error('[BUTTONJOINCALL] Error inicializando llamada', error);
                    this.props.setUserStatus({ status: State.ONLINE });
                    changeUserStateToEndCall(this.props.userId);
                    this.closeInLiveCallModal();
                });

            this.closeInLiveCallModal();
        } catch (error) {
            console.error('[BUTTONJOINCALL] Error creating call:', error);
        }
    };

    handleInLiveCallModal = () => {
        this.setState({ showInLiveCallModal: true });
    };

    closeInLiveCallModal = () => {
        this.setState({ showInLiveCallModal: false });
    };

    render() {
        const { group, showInLiveCallModal } = this.state;
        const { t } = this.props;

        if (!group) {
            return null;
        }

        const button = (
            <button
                type="button"
                className={`${styles.confirmButton} ${!group.live ? styles.disabled : ''}`}
                title={t('GROUPS.LABEL.JoinButton')}
                onClick={group.live ? this.handleInLiveCallModal : null}
                disabled={!group.live}
            >
                {t('GROUPS.LABEL.JoinButton')}
            </button>
        );
        

        return (
            <>
                {!group.live ? (
                    <div className={styles.tooltip}>
                        {button}
                        <span className={styles.tooltiptext}>
                            {t('GROUPS.LABEL.MeetingEnded')}
                        </span>
                    </div>
                ) : (
                    button
                )}
                {showInLiveCallModal && (
                    <ConfirmModal
                        title={t('GROUPS.LABEL.InLiveCallGroup')}
                        message={t('GROUPS.LABEL.ConfirmInLiveCall')}
                        onConfirm={this.handleConfirmCall}
                        onCancel={this.closeInLiveCallModal}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: state.user.id
});

const mapDispatchToProps = (dispatch) => ({
    updateGroup: (group) => dispatch(updateGroup(group)),
    setVideocallInfo: (videocallInfo) => dispatch(setVideocallInfo(videocallInfo)),
    setUserStatus: (status) => dispatch(setUserStatus(status)),
    setSelectedComponent: (component) => dispatch(setSelectedComponent(component)),
});
function ButtonJoinCallNotification(props) {
    const navigate = useNavigate();
    return <BtnJoinCallNotification {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ButtonJoinCallNotification));
