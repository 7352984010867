import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import styles from './styles/groups.module.scss';
import CreateGroup from "./create-group/createGroup.component.jsx";
import GroupCardWithNavigate from "./group-card/groupCard.component.jsx";
import { getGroupsByUserId } from '../../services/GroupService';
import { setGroups } from '../../redux/slices/groupSlice';
import { hasAdminRole, hasProfesionalRole } from "../../utils/credentials.util";
import { Action } from "../../utils/handleActionsEnum";

class Groups extends Component {
    async componentDidMount() {
        await this.fetchGroups();
    }

    fetchGroups = async () => {
        const userId = this.props.user.id;
        try {
            const groups = await getGroupsByUserId(userId);
            this.props.setGroups({ groups });
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
    }

    render() {
        const { t, groups, handleActionClick } = this.props;
        const listClassName = (hasAdminRole() || hasProfesionalRole()) ? styles.listAdmin : styles.listUser;
        const liveGroups = groups.filter(group => group.live === true);
        const otherGroups = groups.filter(group => group.live === false && group.content === null);

        return (
            <>
                {(hasAdminRole() || hasProfesionalRole()) && (
                    <div className={styles.filters}>
                        <CreateGroup />
                    </div>
                )}
                <div className={styles.groups}>
                    <div className={listClassName}>
                        <div className={styles.listContent}>
                            {liveGroups.length > 0 && (
                                <>
                                    <h2 className={styles.sectionTitle}>{t('GROUPS.LABEL.Live')}</h2>
                                    {liveGroups.map((group) => (
                                        <GroupCardWithNavigate
                                            key={group.id}
                                            group={group}
                                            onClick={() => handleActionClick(Action.GROUP_DETAIL, group)}
                                        />
                                    ))}
                                </>
                            )}
                            {otherGroups.length > 0 && (
                                <>
                                    <h2 className={styles.sectionTitle}>{t('GROUPS.LABEL.All')}</h2>
                                    {otherGroups.map((group) => (
                                        <GroupCardWithNavigate
                                            key={group.id}
                                            group={group}
                                            onClick={() => handleActionClick(Action.GROUP_DETAIL, group)}
                                        />
                                    ))}
                                </>
                            )}
                            {groups.length === 0 && (
                                <div>{t('GROUPS.LABEL.NoGroups')}</div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    groups: state.groups.groups,
});

const mapDispatchToProps = {
    setGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Groups));
