import React, {useEffect, useRef, useState} from "react";
import styles from "./styles/contentTable.module.scss";
import {withTranslation} from 'react-i18next';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import {getImage} from '../../../services/BlobService';
import {IconButton, Tooltip} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import {patchContent} from "../../../services/ContentService";
import {store} from "../../../redux/store";
import { useDispatch } from "react-redux";
import { setVideocallInfo } from "../../../redux/slices/videocallSlice";
import { setUserStatus } from "../../../redux/slices/userSlice";
import { State } from "../../../utils/StateEnum";
import { changeUserStateToStartCall, changeUserStateToEndCall } from "../../../services/UserStateService";
import { callGroup } from "../../../services/GroupService";
import { playRingtone } from "../../../utils/videocall.util";
import DEFAULT_BLOB from "../../../assets/img/placeholder100.png"

const ContentRow = ({ item, onEdit, onDelete, t }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLive, setIsLive] = useState(item.live);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allowedNames = ["Actividades_Grupo", "LIVE_Youtube"];

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleCreateCall = async () => {
    if (!isLive) {
      if (item.category.name === "Actividades_Grupo") {
        try {
          const userId = store.getState().user.id;
  
          callGroup(item.groupId)
            .then(callResponse => {
              console.log("[CONTENTROW] Call initiated:", callResponse);
              dispatch(setVideocallInfo(callResponse));
              dispatch(setUserStatus({ status: State.BUSY }));
              changeUserStateToStartCall(userId);
              console.debug(callResponse);
              playRingtone(true);
              navigate('/videocall');
            })
            .catch(error => {
              console.error('Error inicializando llamada', error);
              dispatch(setUserStatus({status: State.ONLINE}));
              changeUserStateToEndCall(userId);
            });
  
        } catch (error) {
          console.error('Error al crear la videollamada o actualizar el contenido:', error);
        }
      }
      const patchData = {
        id: item.id,
        live: true,
        active: item.active
      };

      const updatedContent = await patchContent(item.id, patchData);
      console.log('Contenido actualizado exitosamente:', updatedContent);

      setIsLive(updatedContent.live);
    } else {
      const patchData = {
        id: item.id,
        live: false,
        active: item.active
      };

      try {
        const updatedContent = await patchContent(item.id, patchData);
        console.log('Contenido pausado exitosamente:', updatedContent);

        setIsLive(updatedContent.live);

      } catch (error) {
        console.error('Error al pausar el contenido:', error);
      }
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      let url = DEFAULT_BLOB;
      setIsLoading(true);
      if (item.image) {
        try {
          url = await getImage(item.image);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
        setIsLoading(false);
      }
      setImageUrl(url);
      setIsLoading(false);
    };
    fetchImage();
  }, [item.image]);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  useEffect(() => {
    setIsLive(item.live);
  }, [item.live]);

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} | ${hours}:${minutes}`;
  };

  const statusText = item.active ? (isLive ? t('CONTENT.LABEL.LiveLabel') : t('CONTENT.LABEL.Activated')) : t('CONTENT.LABEL.Deactivated');
  const statusClass = item.active ? (isLive ? styles.live : styles.active) : styles.inactive;


  return (
    <tr className={isLive ? styles.liveBackground : null}>
      <td>
        <div className={styles.contentInfo}>
          <div className={styles.imageContainer}>
            {isLoading ? (
              <div className={styles.loadingIndicator}>
                <Icon icon="eos-icons:loading" width="45" height="45"/>
              </div>
            ) : (
              <img src={imageUrl} alt={item.name} className={styles.contentImage}/>
            )}
            {isLive && (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="white"
              className={styles.playIcon}>
           <path
             d="M6.875 14.3752C6.70924 14.3752 6.55027 14.3093 6.43306 14.1921C6.31585 14.0749 6.25 13.9159 6.25 13.7502V6.25015C6.25006 6.14366 6.27732 6.03895 6.32922 5.94595C6.38111 5.85295 6.4559 5.77476 6.5465 5.71878C6.63709 5.66281 6.74049 5.63091 6.84688 5.62612C6.95327 5.62133 7.05911 5.6438 7.15437 5.6914L14.6544 9.4414C14.7581 9.49337 14.8452 9.57315 14.9061 9.67183C14.9671 9.7705 14.9993 9.88419 14.9993 10.0002C14.9993 10.1161 14.9671 10.2298 14.9061 10.3285C14.8452 10.4272 14.7581 10.5069 14.6544 10.5589L7.15437 14.3089C7.06765 14.3524 6.972 14.3751 6.875 14.3752Z"
             fill="white"/>
           <path
             d="M10 1.25C8.26942 1.25 6.57769 1.76318 5.13876 2.72464C3.69983 3.6861 2.57832 5.05267 1.91606 6.65152C1.25379 8.25037 1.08051 10.0097 1.41813 11.707C1.75575 13.4044 2.58911 14.9635 3.81282 16.1872C5.03653 17.4109 6.59563 18.2442 8.29296 18.5819C9.9903 18.9195 11.7496 18.7462 13.3485 18.0839C14.9473 17.4217 16.3139 16.3002 17.2754 14.8612C18.2368 13.4223 18.75 11.7306 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25ZM14.6544 10.5594L7.15438 14.3094C7.05907 14.357 6.95317 14.3795 6.84673 14.3747C6.74029 14.3698 6.63685 14.3379 6.54624 14.2818C6.45562 14.2258 6.38084 14.1475 6.32899 14.0544C6.27714 13.9613 6.24995 13.8565 6.25 13.75V6.25C6.25006 6.14351 6.27733 6.03879 6.32922 5.94579C6.38111 5.8528 6.4559 5.7746 6.5465 5.71863C6.6371 5.66265 6.7405 5.63076 6.84688 5.62597C6.95327 5.62118 7.05912 5.64365 7.15438 5.69125L14.6544 9.44125C14.7581 9.49321 14.8452 9.57299 14.9061 9.67167C14.9671 9.77035 14.9993 9.88403 14.9993 10C14.9993 10.116 14.9671 10.2297 14.9061 10.3283C14.8452 10.427 14.7581 10.5068 14.6544 10.5587"
             fill="#00AB00"/>
         </svg>
            )}
          </div>
          <div>
            <div className={styles.contentTitle}>{item.description}</div>
            <div className={styles.contentDescription}>{item.name}</div>
          </div>
        </div>
      </td>
      <td>
        <div className={styles.tooltip}>
          <a href={item.resource} target="_blank" rel="noopener noreferrer" className={styles.link}>
            {item.resource.length > 30 ? `${item.resource.substring(0, 20)}...` : item.resource}
          </a>
          <span className={styles.tooltiptext}>{item.resource}</span>
        </div>
      </td>
      <td>{t("CATEGORY."+item.category.name)}</td>
      <td>{item.subcategory ? item.subcategory.name : t("CONTENT.LABEL.Null")}</td>
      <td className={`${styles.status} ${statusClass}`}>
        <div className={styles.statusGroup}>
          <span className={styles.statusText}>
            {isLive && (
              <Icon icon="fluent:video-16-filled" className={styles.statusIcon}/>
            )}
            {statusText}
          </span>
        </div>
      </td>
      <td>{item.creator ? item.creator.email : t("CONTENT.LABEL.Null")}</td>
      <td className={styles.centerColumn}>{item.startDate ? formatDate(item.startDate) : ""}</td>
      <td className={styles.centerColumn}>{item.endDate ? formatDate(item.endDate) : ""}</td>
      <td>
        <div className={styles.dropdownContainer} ref={dropdownRef}>
          {item.active && allowedNames.includes(item.category.name) && (
            <Tooltip title={isLive ? "Pausar directo" : "Iniciar directo"} arrow>
              <IconButton onClick={handleCreateCall} style={{color: 'green'}}>
                {isLive ? <PauseIcon/> : <PlayArrowIcon/>}
              </IconButton>
            </Tooltip>
          )}
          <Icon icon="solar:menu-dots-bold" className={styles.dropIcon} width="20" height="20"
                onClick={handleDropdownToggle}/>
          {showDropdown && (
            <div className={styles.dropdownMenu}>
              <button onClick={() => {
                onEdit(item);
                setShowDropdown(false);
              }}>
                <Icon icon="akar-icons:edit" className={styles.iconButton} width="20"
                      height="20"/>{t("CONTENT.LABEL.Edit")}
              </button>
              <button onClick={() => {
                onDelete(item.id);
                setShowDropdown(false);
              }}>
                <Icon icon="gravity-ui:trash-bin" className={styles.iconButton} width="20"
                      height="20"/>{t("CONTENT.LABEL.Delete")}
              </button>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default withTranslation()(ContentRow);
