import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Contact from '../contact/contact.component';
import Groups from "../groups-view/groups.component";
import { Action } from "../../utils/handleActionsEnum";
import { Icon } from '@iconify/react';
import styles from './styles/menu.module.scss';
import { hasAdminRole, hasProfesionalRole } from '../../utils/credentials.util';

const menu = [
    {
        key: 'contact',
        icon: 'fluent:book-contacts-20-regular',
        label: 'MENU.LABEL.Contacts',
        action: Action.CONTACTS,
    },
    {
        key: 'groups',
        icon: 'material-symbols:groups-outline-rounded',
        label: 'MENU.LABEL.Groups',
        action: Action.GROUPS,
    },
    {
        key: 'content',
        icon: 'fluent:person-info-16-regular',
        label: 'MENU.LABEL.Contents',
        action: Action.CONTENT,
    },
    {
        key: 'homes',
        icon: 'bi:house-gear',
        label: 'MENU.LABEL.Homes',
        action: Action.HOMES,
    },
    {
        key: 'users',
        icon: 'clarity:users-line',
        label: 'MENU.LABEL.Users',
        action: Action.USERS,
    },
];

class IconWithLabel extends Component {
    render() {
        const { icon, label, onClick, menuItemKey, selectedComponent } = this.props;
        const iconClass = selectedComponent === menuItemKey ? `${styles.iconWithLabel} ${styles.selected}` : styles.iconWithLabel;

        return (
            <div className={iconClass} onClick={onClick}>
                <Icon icon={icon} width="25" height="25" />
                <div className={styles.label}>{label}</div>
            </div>
        );
    }
}

class Menu extends Component {
    constructor(props) {
        super(props);
        const savedComponent = this.props.selectComponent || 'contact';
        const savedLayout = this.props.layout || 'horizontal';
        this.state = {
            selectedComponent: savedComponent,
            layout: savedLayout
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedComponent !== prevProps.selectedComponent) {
            console.log("Selected component changed to: ", this.props.selectedComponent);
            this.setState({ selectedComponent: this.props.selectedComponent });
            this.handleIconClick(this.props.selectedComponent, this.props.selectedComponent.toUpperCase(), this.props.handleActionClick, this.props.onLayoutChange);
        }
        if (this.props.layout !== prevProps.layout) {
            console.log("Layout changed to: ", this.props.layout);
            this.layout = styles[this.props.layout];
            this.setState({ layout: this.props.layout });
        }
        if (this.props.selectedMenuComponent !== prevProps.selectedMenuComponent) {
            this.setState({ selectedComponent: this.props.selectedMenuComponent });
        }
    }

    handleIconClick = (key, action, handleActionClick, onLayoutChange) => {
        handleActionClick(action, null);
        /*this.setState({ selectedComponent: key });
        if (['content', 'homes', 'users', 'calendar'].includes(key)) {
            this.layout = styles.vertical;
            onLayoutChange('vertical');
        } else {
            this.layout = styles.horizontal;
            onLayoutChange('horizontal');
        }*/
    }

    render() {
        const { t, handleActionClick, onLayoutChange, selectedComponent } = this.props;
        const componentMap = {
            'contact': <Contact handleActionClick={handleActionClick} />,
            'groups': <Groups handleActionClick={handleActionClick} />,
            'users': null,
            // 'content': <CanalYContenidoComponent />,
            // 'homes': <HomesView />,
        };

        const filteredMenu = menu.filter((option) => {
            if (['content', 'homes', 'users'].includes(option.key)) {
                return hasAdminRole() || hasProfesionalRole();
            }
            return true;
        });

        return (
            <div className={styles.rootContainer}>
                <nav className={`${styles.navigation} ${styles[this.props.layout]}`}>
                    {filteredMenu.map((option) => (
                        <IconWithLabel
                            key={option.key}
                            icon={option.icon}
                            label={t(option.label)}
                            onClick={() => this.handleIconClick(option.key, option.action, handleActionClick, onLayoutChange)}
                            menuItemKey={option.key}
                            selectedComponent={selectedComponent}
                        />
                    ))}
                </nav>
                <div className={styles.componentDisplay}>
                    {componentMap[selectedComponent] || null}
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    layout: state.layout.selectedLayout || 'horizontal',
    selectedComponent: state.layout.selectedComponent || 'contact',
});

export default connect(mapStateToProps)(withTranslation()(Menu));
