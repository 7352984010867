import { store } from '../redux/store';

import { clearSession } from '../redux/slices/sessionSlice';
import { clearLanguage } from '../redux/slices/languageSlice';
import { clearSelectedProfileView } from '../redux/slices/profileSlice';
import { clearTimeZone } from '../redux/slices/timeZoneSlice';
import { clearTenant } from '../redux/slices/tenantSlice';
import { clearSelectedContact, clearContacts } from '../redux/slices/contactSlice';
import { clearSelectedLayout, clearSelectedComponent, clearSelectedMenuComponent } from '../redux/slices/layoutSlice';
import { clearNotifications, clearLastNotification } from '../redux/slices/notificationSlice';
import { clearUserRoles, clearUserInfo, clearUserStatus, clearUserHomes } from '../redux/slices/userSlice';
import { clearUserTypes } from '../redux/slices/userTypeSlice';
import { clearShowVideocall, clearVideocallInfo } from '../redux/slices/videocallSlice';
import { clearGroups, clearSelectedGroup } from '../redux/slices/groupSlice';
import { clearRows } from '../redux/slices/contentSlice';

export const clearReduxState = () => {
    const { dispatch } = store;

    try {
        dispatch(clearSession());
        dispatch(clearLanguage());
        dispatch(clearSelectedProfileView());
        dispatch(clearTimeZone());
        dispatch(clearTenant());
        dispatch(clearSelectedContact());
        dispatch(clearContacts());
        dispatch(clearSelectedLayout());
        dispatch(clearSelectedComponent());
        dispatch(clearSelectedMenuComponent());
        dispatch(clearNotifications());
        dispatch(clearLastNotification());
        dispatch(clearUserRoles());
        dispatch(clearUserInfo());
        dispatch(clearUserStatus());
        dispatch(clearUserHomes());
        dispatch(clearUserTypes());
        dispatch(clearVideocallInfo());
        dispatch(clearGroups());
        dispatch(clearSelectedGroup());
        dispatch(clearRows());
        dispatch(clearShowVideocall());
    } catch (error) {
        console.error("Error en clearReduxState:", error);
    }
};

