import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import UploadFileModal from '../../upload-file-modal/uploadFileModal.component';
import { createUser } from '../../../services/UserService';
import { getUserTypes } from '../../../services/UserTypeService';
import { searchHomes } from '../../../services/HomeService';
import DropdownSelect from '../../dropdown-select/dropdownSelect.component';
import CustomInput from '../../custom-input/customInput.component';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';
import styles from './styles/createUsers.module.scss';
import greyImage from '../../../assets/img/imagegrey.png';
import blueImage from '../../../assets/img/imageblue.png';

class CreateUsers extends Component {
  state = {
    usersData: {
      user: '',
      name: '',
      surname: '',
      password: '',
      phone: '',
      birthDate: '',
      direction: '',
      locality: '',
      province: '',
      postalCode: ''
    },
    userTypes: [],
    homes: [],
    selectedHomes: [],
    selectedUserType: null,
    imageSrc: greyImage,
    isUploadModalOpen: false,
    selectedFile: null,
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
    errors: {},
    validate: false,
    isLoading: false,
  };

  async componentDidMount() {
    try {
      const userTypes = await getUserTypes();
      this.setState({ userTypes: userTypes.map(type => ({ key: type.id, value: this.props.t("USER_TYPE." + type.name) })) });
      const homes = await this.searchHomes("", true);
      this.setState({ homes });
    } catch (error) {
      console.error('Error obteniendo users:', error);
    }
  }

  handleSave = async () => {
    const { usersData, selectedUserType } = this.state;
    const errors = {};

    if (!usersData.name) errors.name = "Este campo es obligatorio";
    if (!usersData.surname) errors.surname = "Este campo es obligatorio";
    if (!usersData.password) errors.password = "Este campo es obligatorio";
    if (!usersData.user) errors.user = "Este campo es obligatorio";
    if (usersData.user && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(usersData.user)) errors.user = "El email no es válido";
    if (!selectedUserType) errors.selectedUserType = "Este campo es obligatorio";

    this.setState({ errors, validate: true });

    if (Object.keys(errors).length > 0) {
      return;
    }

    const {
      selectedHomes,
      selectedFile
    } = this.state;

    const data = {
      file: selectedFile,
      email: usersData.user,
      name: usersData.name,
      surname: usersData.surname,
      password: usersData.password,
      phone: usersData.phone,
      birthDate: usersData.birthDate,
      direction: usersData.direction,
      locality: usersData.locality,
      province: usersData.province,
      postalCode: usersData.postalCode,
      userTypeId: selectedUserType.key || null,
      homeIds: selectedHomes.map(home => home.key),
      active: true,
      moderator: false
    };

    this.setState({ isLoading: true });

    try {
      await createUser(data);
      this.props.onClose(true);
    } catch (error) {
      console.error('Error creando users:', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("USERS.CREATE.ErrorMessage"),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
    this.props.onClose(this.state.feedbackType === 'ok');
  };

  setSelectedHomes = async (selectedOptions) => {
    this.setState({ selectedHomes: Array.isArray(selectedOptions) ? selectedOptions : [] });
  }

  setSelectedUserType = (selectedOptions) => {
    this.setState({ selectedUserType: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  searchHomes = async (term) => {
    try {
      const withActive = true;
      const homes = await searchHomes(term, withActive);
      return homes.map(home => ({ key: home.id, value: home.niu }));
    } catch (error) {
      console.error('Error buscando homes:', error);
      return [];
    }
  }

  handleInputChange = (name, value) => {
    this.setState((prevState) => ({
      usersData: {
        ...prevState.usersData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: null
      }
    }));
  }

  handleImageMouseEnter = () => {
    if (this.state.selectedFile) return;
    this.setState({ imageSrc: blueImage });
  };

  handleImageMouseLeave = () => {
    if (this.state.selectedFile) return;
    this.setState({ imageSrc: greyImage });
  };

  handleImageClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  handleEditClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalOpen: false });
  };

  handleFileSelect = (file) => {
    this.setState({ selectedFile: file, imageSrc: URL.createObjectURL(file) });
  };

  render() {
    const { homes, userTypes, usersData, imageSrc, isUploadModalOpen, validate, isFeedbackModalOpen, feedbackType, feedbackMessage, isLoading } = this.state;
    const { t } = this.props;
    const buttons = [
      { label: t("USERS.CREATE.SaveButton"), className: styles.saveButton, onClick: this.handleSave },
      { label: t("USERS.CREATE.CancelButton"), className: styles.cancelButton, onClick: () => this.props.onClose(false) }
    ];

    return (
      <>
        {isLoading && <LoadingScreen />}
        <Modal title={t("USERS.CREATE.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
          <div className={styles.formUsers}>
            <div className={styles.row}>
              <div className={styles.imageFormHeader}>
                <label className={styles.label}>{t("USERS.CREATE.Image")}</label>
                <span className={styles.editLabel} onClick={this.handleEditClick}>{t("USERS.CREATE.editImage")}</span>
              </div>
              <img
                src={imageSrc}
                alt=""
                className={styles.image}
                onMouseEnter={this.handleImageMouseEnter}
                onMouseLeave={this.handleImageMouseLeave}
                onClick={this.handleImageClick}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("USERS.CREATE.User")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="user"
                value={usersData.user}
                onChange={(value) => this.handleInputChange('user', value)}
                placeholder={t("USERS.CREATE.UserPlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: t("USERS.CREATE.ValidationEmail") }]}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.Name")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="name"
                value={usersData.name}
                onChange={(value) => this.handleInputChange('name', value)}
                placeholder={t("USERS.CREATE.NamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("USERS.CREATE.ValidationRequired") }]}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.Surname")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="surname"
                value={usersData.surname}
                onChange={(value) => this.handleInputChange('surname', value)}
                placeholder={t("USERS.CREATE.SurnamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("USERS.CREATE.ValidationRequired") }]}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("USERS.CREATE.Password")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="password"
                name="password"
                value={usersData.password}
                onChange={(value) => this.handleInputChange('password', value)}
                placeholder={t("USERS.CREATE.PasswordPlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("USERS.CREATE.ValidationRequired") }]}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.Phone")}</label>
              <CustomInput
                type="text"
                name="phone"
                value={usersData.phone}
                onChange={(value) => this.handleInputChange('phone', value)}
                placeholder={t("USERS.CREATE.PhonePlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.BirthDate")}</label>
              <CustomInput
                type="date"
                name="birthDate"
                value={usersData.birthDate}
                onChange={(value) => this.handleInputChange('birthDate', value)}
                placeholder={t("USERS.CREATE.BirthDatePlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("USERS.CREATE.Direction")}</label>
              <CustomInput
                type="text"
                name="direction"
                value={usersData.direction}
                onChange={(value) => this.handleInputChange('direction', value)}
                placeholder={t("USERS.CREATE.DirectionPlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.Locality")}</label>
              <CustomInput
                type="text"
                name="locality"
                value={usersData.locality}
                onChange={(value) => this.handleInputChange('locality', value)}
                placeholder={t("USERS.CREATE.LocalityPlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.Province")}</label>
              <CustomInput
                type="text"
                name="province"
                value={usersData.province}
                onChange={(value) => this.handleInputChange('province', value)}
                placeholder={t("USERS.CREATE.ProvincePlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.PostalCode")}</label>
              <CustomInput
                type="text"
                name="postalCode"
                value={usersData.postalCode}
                onChange={(value) => this.handleInputChange('postalCode', value)}
                placeholder={t("USERS.CREATE.PostalCodePlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
            </div>
            <div className={`${styles.row} ${styles.spaceBetween}`}>
              <div className={styles.row}>
                <label className={styles.label}>{t("USERS.CREATE.UserType")}</label>
                <DropdownSelect
                  options={userTypes}
                  isMultiple={false}
                  placeholder={t("USERS.CREATE.SelectUserTypePlaceholder")}
                  setSelectedOptions={this.setSelectedUserType}
                  customClass={styles.selectUserType}
                  required={true}
                  validate={validate}
                />
              </div>
              <div className={styles.row}>
                <label className={styles.label}>{t("USERS.CREATE.SelectHome")}</label>
                <DropdownSelect
                  options={homes}
                  isMultiple={true}
                  placeholder={t("USERS.CREATE.SelectHomePlaceholder")}
                  setSelectedOptions={this.setSelectedHomes}
                  searchable={true}
                  search={this.searchHomes}
                  customClass={styles.selectHome}
                />
              </div>
            </div>
          </div>
        </Modal>
        {isUploadModalOpen && <UploadFileModal onClose={this.closeUploadModal} onFileSelect={this.handleFileSelect} />}
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(CreateUsers);
