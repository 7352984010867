import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import GroupDetail from '../groups-view/group-detail/groupDetail.component';
import EmptyContent from '../empty-content/empty-content.component';
import { hasFamiliarRole } from '../../utils/credentials.util';
import VideoCallWrapper from './VideoCallWrapper';

const GroupsWrapper = () => {
    const selectedGroup = useSelector(state => state.groups.selectedGroup);
    const isFamiliarRole = hasFamiliarRole();
    const videocallInfo = useSelector((state) => state.videocall.videocallInfo);
    const showVideocall = useSelector((state) => state.videocall.showVideocall);

    useEffect(() => {
        if (isFamiliarRole) {
            store.dispatch(setSelectedComponent('outlet'));
        } else {
            store.dispatch(setSelectedComponent('groups'));
            store.dispatch(setSelectedLayout('horizontal'));
        }
    }, [isFamiliarRole]);

    useEffect(() => {
        if (isFamiliarRole && !selectedGroup) {
            store.dispatch(setSelectedComponent('groups'));
        }
    }, [isFamiliarRole, selectedGroup]);

    if (isFamiliarRole) {
        if (videocallInfo && showVideocall) {
            return <VideoCallWrapper />;
        }
        if (selectedGroup) {
            return <GroupDetail group={selectedGroup} showHistory={true} />;
        }
    } else {
        return selectedGroup ? (
            <GroupDetail group={selectedGroup} showHistory={true} />
        ) : (
            <EmptyContent view="groups" />
        );
    }
};

export default GroupsWrapper;
