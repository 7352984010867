import {jwtDecode} from 'jwt-decode';
import {setUserInfo, setUserRoles} from '../../redux/slices/userSlice';
import {errorHandler} from '../../utils/errorHandler.js';
import {store} from '../../redux/store';
import {removeToken} from '../notification/FirebaseService';
import { clearReduxState } from '../../utils/clearRedux.util';
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const AuthService = {
    auth: async (username, password) => {
        try {
            const response = await fetch(API_URL + '/api/v1/auth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });

            errorHandler(response.status);

            if (response.ok) {
                const data = await response.json();
                clearReduxState();
                sessionStorage.setItem('accessToken', data.access_token);
                sessionStorage.setItem('idToken', data.id_token);
                const decodedToken = jwtDecode(data.access_token);
                const roles = decodedToken.roles;
                store.dispatch(setUserInfo({ email: decodedToken.email}));
                store.dispatch(setUserRoles({ roles: roles }));
            }

            return response;
        } catch (error) {
            console.error('Error al autenticar:', error);
            throw error;
        }
    },
    logout: async () => {
        const idToken = sessionStorage.getItem('idToken');
        try {
            const response = await fetch(API_URL + '/api/v1/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ idToken })
            });
            await removeToken();
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('idToken');
            clearReduxState();
            //await persistor.purge();

            errorHandler(response.status);

        } catch (error) {
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('idToken');
            clearReduxState();
            //persistor.purge();
            console.error('Error al autenticar:', error);
            throw error;
        }
    },
    checkAuth: () => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                return decodedToken.exp * 1000 > Date.now();
            } catch (error) {
                console.error('Error al decodificar el token:', error);
                return false;
            }
        }
        return false;
    },
    checkToken: (token) => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                return decodedToken.exp * 1000 > Date.now();
            } catch (error) {
                console.error('Error al decodificar el token:', error);
                return false;
            }
        }
        return false;
    }
};

export const { auth, logout, checkAuth, checkToken } = AuthService;
