import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import HomesView from '../homes-view/homesView.component';
import { hasAdminRole, hasProfesionalRole } from '../../utils/credentials.util';

const HomesWrapper = () => {
    useEffect(() => {
        store.dispatch(setSelectedComponent('homes'));
        store.dispatch(setSelectedLayout('vertical'));
    }, []);

    const hasAccessToProtectedRoutes = hasAdminRole() || hasProfesionalRole();

    if (!hasAccessToProtectedRoutes) {
        return <Navigate to="/contacts" replace />;
    }

    return <HomesView />;
};

export default HomesWrapper;
