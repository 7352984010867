import React, { Component } from "react";
import CryptoJS from 'crypto-js';
import { withTranslation } from 'react-i18next';
import logoTunstall from '../../assets/img/tunstall.svg';
import styles from './styles/videocallInvitation.module.scss';
import { parse, format, differenceInMilliseconds } from 'date-fns';
import meetingEnded from '../../assets/img/meetingEnded.svg';
import meetingSoon from '../../assets/img/meetingSoon.svg';

class VideocallInvitation extends Component {
    constructor(props) {
        super(props);

        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
        const key = CryptoJS.enc.Utf8.parse(encryptionKey.padEnd(16, ' '));
        const iv = CryptoJS.enc.Utf8.parse('\0'.repeat(16));

        const encryptedCall = decodeURIComponent(this.props.call);
        const encryptedCallBytes = CryptoJS.enc.Base64.parse(encryptedCall);
        const decryptedCallBytes = CryptoJS.AES.decrypt({ ciphertext: encryptedCallBytes }, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const decryptedCall = decryptedCallBytes.toString(CryptoJS.enc.Utf8);

        const encryptedDates = decodeURIComponent(this.props.dates || '');
        let startDate = null;
        let endDate = null;

        if (encryptedDates) {
            const encryptedDatesBytes = CryptoJS.enc.Base64.parse(encryptedDates);
            const decryptedDatesBytes = CryptoJS.AES.decrypt({ ciphertext: encryptedDatesBytes }, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            const decryptedDates = decryptedDatesBytes.toString(CryptoJS.enc.Utf8);
            console.log('decryptedDates', decryptedDates);
            const params = new URLSearchParams(decryptedDates);

            const startDateStr = params.get('startDate');
            const endDateStr = params.get('endDate');

            const dateFormat = 'dd/MM/yyyy HH:mm';
            if (startDateStr) {
                startDate = this.parseDate(startDateStr, dateFormat);
            }
            if (endDateStr) {
                endDate = this.parseDate(endDateStr, dateFormat);
            }
        }

        this.state = {
            decryptedCall: decryptedCall,
            startDate: startDate,
            endDate: endDate,
            timeRemaining: null,
            isInCall: false,
        };

        this.handleInCall = this.handleInCall.bind(this);
    }

    componentDidMount() {
        this.updateTimeRemaining();
        this.timer = setInterval(() => {
            this.updateTimeRemaining();
        }, 1000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    parseDate(dateStr, formatStr) {
        const parsedDate = parse(dateStr, formatStr, new Date());
        return parsedDate;
    }

    updateTimeRemaining() {
        const now = new Date();
        const { startDate } = this.state;

        if (startDate && now < startDate) {
            const timeDiff = differenceInMilliseconds(startDate, now);
            this.setState({ timeRemaining: timeDiff });
        } else {
            this.setState({ timeRemaining: null });
        }
    }

    formatNumber(number) {
        return String(number).padStart(2, '0');
    }

    handleInCall() {
        this.setState({ isInCall: true });
    }

    render() {
        const { decryptedCall, startDate, endDate, timeRemaining, isInCall } = this.state;
        const now = new Date();
        const { t } = this.props;

        if (startDate && now < startDate) {
            const totalSeconds = Math.floor(timeRemaining / 1000);
            const days = Math.floor(totalSeconds / (3600 * 24));
            const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            return (
                <>
                    <div className={styles.header}>
                        <img src={logoTunstall} alt="Logo" className={styles.logo} />
                    </div>
                    <div className={styles.messageContainer}>
                        <img src={meetingSoon} alt="Meeting Soon" className={styles.meetingImage} />
                        <h1 className={styles.meetingTitle}>{t('VIDEOCALL_INVITATION.MeetingNotStarted')}</h1>
                        <p>{t('VIDEOCALL_INVITATION.MeetingStartsAt', { date: format(startDate, 'dd/MM/yyyy HH:mm') })}</p>
                        <div className={styles.countdown}>
                            {days > 0 && (
                                <div className={styles.countdownItem}>
                                    <div className={styles.countdownNumber}>{this.formatNumber(days)}</div>
                                    <div className={styles.countdownLabel}>{t('VIDEOCALL_INVITATION.Days')}</div>
                                </div>
                            )}
                            <div className={styles.countdownItem}>
                                <div className={styles.countdownNumber}>{this.formatNumber(hours)}</div>
                                <div className={styles.countdownLabel}>{t('VIDEOCALL_INVITATION.Hours')}</div>
                            </div>
                            <div className={styles.countdownItem}>
                                <div className={styles.countdownNumber}>{this.formatNumber(minutes)}</div>
                                <div className={styles.countdownLabel}>{t('VIDEOCALL_INVITATION.Minutes')}</div>
                            </div>
                            <div className={styles.countdownItem}>
                                <div className={styles.countdownNumber}>{this.formatNumber(seconds)}</div>
                                <div className={styles.countdownLabel}>{t('VIDEOCALL_INVITATION.Seconds')}</div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (endDate && now > endDate && !isInCall) {
            return (
                <>
                    <div className={styles.header}>
                        <img src={logoTunstall} alt="Logo" className={styles.logo} />
                    </div>
                    <div className={styles.messageContainer}>
                        <img src={meetingEnded} alt="Meeting End" className={styles.meetingImage} />
                        <h1 className={styles.meetingTitle}>{t('VIDEOCALL_INVITATION.MeetingEnded')}</h1>
                        <p>{t('VIDEOCALL_INVITATION.MeetingEndedAt', { date: format(endDate, 'dd/MM/yyyy HH:mm') })}</p>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className={styles.header}>
                        <img src={logoTunstall} alt="Logo" className={styles.logo} />
                    </div>
                    <div className={styles.videocallContainer}>
                    <div className={styles.messageContainer}>
                        <img src={meetingEnded} alt="Meeting End" className={styles.meetingImage} />
                        <h1 className={styles.meetingTitle}>{t('VIDEOCALL_INVITATION.RefreshToRejoin')}</h1>
                    </div>
                        <iframe
                            src={decryptedCall}
                            title="VideoCallInvitation"
                            allow="camera; microphone"
                            className={styles.videocall}
                            onLoad={this.handleInCall}
                        ></iframe>
                    </div>

                </>
            );
        }
    }
}

export default withTranslation()(VideocallInvitation);
