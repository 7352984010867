import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import ContactDetail from '../contact-detail/contactDetail.component';
import EmptyContent from '../empty-content/empty-content.component';
import { hasFamiliarRole } from '../../utils/credentials.util';
import VideoCallWrapper from './VideoCallWrapper';

const ContactsWrapper = () => {
    const selectedContact = useSelector(state => state.contact.selectedContact);
    const videocallInfo = useSelector((state) => state.videocall.videocallInfo);
    const showVideocall = useSelector((state) => state.videocall.showVideocall);

    const isFamiliarRole = hasFamiliarRole();

    useEffect(() => {
        if (isFamiliarRole) {
            store.dispatch(setSelectedComponent('outlet'));
        } else {
            store.dispatch(setSelectedComponent('contact'));
            store.dispatch(setSelectedLayout('horizontal'));
        }
    }, [isFamiliarRole]);

    useEffect(() => {
        if (isFamiliarRole && !selectedContact) {
            store.dispatch(setSelectedComponent('contact'));
        }
    }, [isFamiliarRole, selectedContact]);

    if (isFamiliarRole) {
        if (videocallInfo && showVideocall) {
            return <VideoCallWrapper />;
        }
        if (selectedContact) {
            return <ContactDetail contact={selectedContact} showHistory={true} />;
        }
    } else {
        return selectedContact ? (
            <ContactDetail contact={selectedContact} showHistory={true} />
        ) : (
            <EmptyContent view="contact" />
        );
    }
};

export default ContactsWrapper;
